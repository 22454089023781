import React from "react"
import { graphql } from "gatsby"

// Components
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import { Image, Layout } from '../components'

const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 767 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1
    }
}

const About = props => {

    return ( 
        <Layout
            title = { 'Our Story' }
        >
            <div className="site-About">
                <div 
                    style = {{
                        width: '100vw',
                        minHeight : '90vh',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'center'
                    }}
                >
                    <p align = 'left'
                        style = {{
                            whiteSpace : 'pre-line',
                            width: '70vw',
                            marginTop: '10vh',
                            minHeight : '40vh',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'center'
                        }}
                    >
                        <h1>Our Story</h1>
                        <strong>The Legend Continues . . .</strong>
                        <br/>
                        { props.data.allDatoCmsAbout.edges[0].node.story }
                    </p>
                    <div 
                        style = {{
                            width : '70vw'
                        }}
                    >
                        <Carousel 
                            responsive = { responsive }
                            infinite = { false }
                        >
                            <Image
                                src = { props.data.allDatoCmsAbout.edges[0].node.ourStoryImageLeft.url }
                                style = {{
                                    margin: '1vw'
                                }}
                            />
                            <Image
                                src = { props.data.allDatoCmsAbout.edges[0].node.ourStoryImageRight.url }
                                style = {{
                                    margin: '1vw'
                                }}
                            />
                        </Carousel>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
export default About

export const query = graphql`
    query StoryQuery {
        allDatoCmsAbout {
            edges {
                node {
                    story
                    ourStoryImageLeft {
                        url
                    }
                    ourStoryImageRight {
                        url
                    }
                }
            }
        }
    }
`